/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useAnalyticsContext } from "@xala/common";
import React, { memo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { MediaButtonVariant } from "enums";

import ClipboardIcon from "../../resources/icons/clipboard.svg";
import { MediaButton } from "../MediaButton";
import { Tooltip } from "../Tooltip";

export const ClipboardCopyButton = withTranslation()(
  memo(({ t }: WithTranslation) => {
    const { share } = useAnalyticsContext();
    const onClick = () => {
      share();
      //navigator.clipboard.writeText(window.location.href);
      const l = window.location;
      navigator.clipboard.writeText(l.origin + "/share" + l.pathname);
    };

    return (
      <Tooltip title={t("ASSET_COPY_LINK_TO_CLIPBOARD")} trigger="click">
        <MediaButton
          variant={MediaButtonVariant.Transparent}
          icon={<ClipboardIcon />}
          iconElevated={true}
          onClick={onClick}
        />
      </Tooltip>
    );
  })
);
